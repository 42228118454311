import '/assets/styles/pages/error.scss';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';

// Context
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

// Static Assets
import errorImage from '/assets/images/error/404-web.jpg';
import errorImageMobile from '/assets/images/error/404-mobile.jpg';

const Error = () => {
	const { mobile } = useBreakpoints();
	const { activeRoute } = useNavigator();

	return <main className="page error">
		<div className="error-container">
			<Img cover className="error-bg" src={mobile ? errorImageMobile : errorImage} />
			<div className="error-innerwrap wrapper">
				<div className="error-text">
					<span className="text-code">404</span>
					<h1 className="text-title">Sayfa Bulunamadı</h1>

					<p className="text-message">
						<Link href="home">Anasayfaya</Link> dönerek alışverişe başlayabilirsiniz.
					</p>
				</div>
			</div>
		</div>
	</main>
}

export default Error